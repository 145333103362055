import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';
import { useInView } from 'react-intersection-observer';
import classname from 'classnames';
import './rowLayoutStyles.scss';
import { inlineStyles, parseAttributes } from '../utils';




export const RowLayout = (props) => {
    const { innerBlocks, reusableBlocks, innerHTML, parsedContent, content, attrs } = props;

    // if (props.attrs.uniqueID === "_9fcae3-ae") {
    //     console.log(props);
    // }

    let wrapperDivId;
    if (typeof window !== 'undefined') {

        const parser = new DOMParser();
        const doc = parser.parseFromString(innerHTML, 'text/html');

        // Find the first div
        const firstDiv = doc.querySelector('div');

        // Check if the id does not contain 'kt-layout-id' and assign it to a variable if true
        if (firstDiv && !firstDiv.id.includes('kt-layout-id')) {
            wrapperDivId = firstDiv.id;
        }

    }

    const styles = inlineStyles(props, 'kt-layout-id');

    const { uniqueID, kadenceBlockCSS } = attrs;
    const id = `kt-row-layout-${uniqueID}`;

    // if (uniqueID === '_33e36f-1d') {
    //     console.log('Layout', innerHTML);
    // }


    const attributes = parseAttributes(props);


    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    let group = null;
    if (innerBlocks && innerBlocks.length > 0 && parsedContent && content) {
        group = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);
    }


    useEffect(() => {
        const scrollAmount = 300;
        const scrollContainer = document.querySelector('.attending-scroll-container');
        const arrowLeft = document.querySelector('.who-attending-arrow-left');
        const arrowRight = document.querySelector('.who-attending-arrow-right');
        if (scrollContainer && arrowLeft && arrowRight) {

            arrowLeft.addEventListener('click', () => {
                scrollContainer.scrollBy({
                    top: 0,
                    left: -scrollAmount,
                    behavior: 'smooth'
                });
            });

            arrowRight.addEventListener('click', () => {
                scrollContainer.scrollBy({
                    top: 0,
                    left: scrollAmount,
                    behavior: 'smooth'
                });
            });
        }
        // return () => {
        //     const arrowLeft = document.querySelector('.who-attending-arrow-left');
        //     const arrowRight = document.querySelector('.who-attending-arrow-right');
        //     if (arrowLeft) arrowLeft.removeEventListener('click', scrollLeft);
        //     if (arrowRight) arrowRight.removeEventListener('click', scrollRight);
        // };

    }, []);
    const noAnimation = attributes.container.classes?.includes('no-animation');
    const noParentAnimation = attributes.container.classes?.includes('no-parent-animation');

    const obj = {
        // 'to-be-faded-in': true,
        ...(noParentAnimation ? {} : {
            'to-be-faded-in': !inView,
            'fade-in-section': inView
        }),
        [attributes.container.classes]: true,
        'no-animation': noAnimation
    };
    var arrayClasses = attributes.container.classes.split(" ");
    arrayClasses.forEach(item => {
        obj[item] = true;
    })
    const containerClassNames = classname(obj);
    // console.log(props)
    return (
        <>
            {styles}
            {kadenceBlockCSS && ReactHtmlParser(`<style>${ReactHtmlParser(`${kadenceBlockCSS.replace(new RegExp("selector", 'g'), "#" + id)}`)}</style>`)}
            <div ref={ref} id={id} className={containerClassNames} >
                {wrapperDivId ? <span id={wrapperDivId} className='block-custom-id'></span> : null}
                <div id={attributes.innerContainer.id} className={attributes.innerContainer.classes}>
                    <div className={attributes.columnsContainer.classes}>
                        {group}
                    </div>
                </div>
            </div>
        </>
    );
};

RowLayout.propTypes = {
    attrs: PropTypes.object
}
