import React, { cloneElement } from 'react';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';
import ReactHtmlParser from 'react-html-parser';

import './style.scss';

const CoreListItem = (props) => {

	const { innerBlocks, innerHTML, reusableBlocks, parsedContent, content } = props;


	if (!innerBlocks || innerBlocks?.length < 1) {
		return (<>{ReactHtmlParser(innerHTML)}</>);
	}


	let group = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);

	const liRegex = /<li>(.*?)<\/li>/g;

	// Extract matches
	const liItems = [];
	let match;
	while ((match = liRegex.exec(innerHTML)) !== null) {
		liItems.push(match[1]); // Capture group 1 contains the content between the tags
	}

	return (<li>{ReactHtmlParser(liItems)}{group}</li>);
};

export default CoreListItem;