import React from 'react';
import { collectInnerBlocks } from '@blocks/MapBlocks/MapBlocks';

import './style.scss';

const CoreList = (props) => {

	const { innerBlocks, innerHTML, reusableBlocks, parsedContent, content } = props;

	if (!innerBlocks) {
		return null;
	}

	let isOl = /<ol\b/.test(innerHTML);
	let group = collectInnerBlocks(innerBlocks, reusableBlocks, parsedContent, content);

	if (isOl) {
		return (<ol className='core-list numbered-list'>{group}</ol>);
	}
	return (<ul className='core-list order-list'>{group}</ul>);
};

export default CoreList;